.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 8px;
  padding: 8px 14px;

  &__portal {
    z-index: 10000 !important;
  }

  &__top-section {
    &__text {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      color: $gray-800;
      opacity: 0.3;
    }

    &__close-button {
      font-size: 28px;
      border: none;
      background-color: transparent;
    }
  }

  &__navigation {
    &__button {
      font-size: 20px;
      border: none;
      background-color: transparent;
    }
  }

  &__header {
    background-color: transparent;
    border: none;
  }

  &__current-month {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 20px;
    padding-top: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    color: #343a40 !important;
  }

  &__day-name {
    color: #74788d !important;
    font-weight: 500;
  }

  .react-datepicker__navigation {
    top: 55px !important;
  }

  &__navigation-icon--next::before,
  &__navigation-icon--previous::before {
    height: 8px !important;
    width: 8px !important;
    border-color: #a6b0cf;
    border-width: 1px 1px 0 0;
  }

  &__children-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__ok-button {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: $blue;
    border: none;
    background-color: transparent;

    &:disabled {
      opacity: 0.5;
    }
  }

  &__day {
    color: #74788d;
    border: 1px solid transparent;

    &--disabled {
      opacity: 0.2;
    }

    &--outside-month {
      opacity: 0.4;
    }

    &--today {
      background-color: transparent;
      border-color: #556ee6;
      border-radius: 0.3rem;
      color: #556ee6;
      font-weight: 600;
    }

    &--selected {
      background-color: #5c8db3 !important;
    }

    &--in-selecting-range {
      background-color: red;
    }

    &--in-range {
      background-color: rgba(132, 196, 255, 0.4);
    }

    &--range-start {
      background-color: #556ee6;
      border-color: #556ee6;
      color: #ffffff;
    }

    &--range-end {
      background-color: #556ee6;
      border-color: #556ee6;
      color: #ffffff;
    }

    &:hover {
      background-color: rgba(132, 196, 255, 0.4);
      border-color: #556ee6;
      color: #556ee6;
    }
  }

  &__open-button {
    cursor: pointer;
    border-radius: 4px;
    border: none;
    line-height: 20px;

    &-free-subscription {
      padding: 8px 13px;
      background-color: $primary;
      color: #ffffff;
      border: 1px solid $primary;
    }

    &-expiration-date {
      padding: 4px 8px;
      background-color: transparent;
      color: $primary;
      border: 1px solid $primary;
    }
  }
}
