//
// _imageSquare.scss
//

.imageSquare-root {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.imageSquare-box {
  position: absolute;
  width: 100%;
  height: 100%;
}

.imageSquare-image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;
}
