//
// settings.scss
//

.settings-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

.settings-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;

  @include media-breakpoint-down('md') {
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 24px;
    }
  }
}

.settings-form-box {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
}

.settings-form {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.settings-subtitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
}

.settings-button-box {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 2;
}

.settings-button {
  @include media-breakpoint-down('md') {
    width: 100%;
    margin-top: 8px;
  }
}
