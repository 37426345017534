.subscriptions-table-header {
  white-space: nowrap;
  color: $gray-800;
  background-color: $gray-100 !important;

  &.column {
    width: 273px;
  }
}

.user-subscriptions-page {
  .subscription-actions {
    .btn {
      padding: 4px 8px;
    }

    &__button {
      &-cancel {
        margin-left: 8px;
      }
    }
  }
}

.info-text {
  &__head {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
  }

  &__tooltip {
    .tooltip.show {
      opacity: 1;
    }

    .tooltip-inner {
      background-color: $white;
      color: $gray-800;
      padding: 8px 10px;
      border-radius: 4px;
      box-shadow: 0 0 1px 0 #091e424f;
    }

    .tooltip-arrow {
      display: none;
    }
  }
}

.post-count-info {
  .info-text__head {
    max-width: 200px;
  }
}

.post-count-info__inner {
  min-width: 170px;
  text-align: left;
  font-size: 13px;
}
