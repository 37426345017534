.table-caption {
  font-weight: 600;
}

.users-page-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 6px;
  text-transform: uppercase;
}

.users-page-content {
  min-height: 749px;
}

.users-table {
  min-height: 70vh;

  &--filters {
    min-height: pxToRem(50);
    margin-bottom: pxToRem(10);

    &--dropdown {
      border: 1px $gray-200;
      padding: pxToRem(8) pxToRem(12);
      padding-bottom: pxToRem(14);
      width: pxToRem(217);
      font-size: pxToRem(14);

      &__title {
        font-size: pxToRem(14);
        font-weight: 600;
        margin-bottom: pxToRem(20);
      }

      &__next-section {
        margin-top: pxToRem(15);
      }

      &__toggle {
        box-sizing: border-box;
        border: 1px solid #556ee6 !important;
        border-radius: pxToRem(4) !important;
        color: #556ee6 !important;
        font-size: pxToRem(13);
        line-height: pxToRem(20);
        text-align: center;
        outline: none;
        padding: 8px 13px !important;
      }

      &__toggle:hover {
        color: #556ee6;
        background-color: rgba($color: #556ee6, $alpha: 0.2);
        outline: none;
      }

      .form-check {
        margin-top: pxToRem(10);
      }
    }
  }
}

.filters-dropdown {
  margin-top: 4px;

  &__clear {
    @media (min-width: 507px) {
      margin-right: 50px !important;
    }
    @media (max-width: 506px) {
      margin-right: -82px !important;
    }
  }
  &__without-clear {
    @media (max-width: 506px) {
      margin-right: -124px !important;
    }
  }
}

.filters-radio {
  &:checked {
    &:before {
      background-color: white;
    }

    background-color: transparent;
    background-image: escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#556EE6'/></svg>")
    ) !important;
  }
}

.user-status-badge {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-row {
  padding: 12px 10px;
  white-space: nowrap;
  color: $gray-800;
}

.table-header {
  white-space: nowrap;
  color: $gray-800;
  background-color: $gray-100 !important;
  &.id {
    width: 150px;
  }
  &.name {
    width: 301px;
  }
  &.number {
    width: 180px;
  }
  &.join-date {
    width: 150px;
  }
  &.sub {
    width: 176px;
  }
  &.status {
    width: 136px;
  }
}

.users-table-wrap {
  overflow-x: auto;
  min-height: 578px;
}

.users-table-loader {
  height: 632px;
}

.header-button {
  border: 0;
  background-color: transparent;

  &.disabled {
    pointer-events: none;
  }
}
