//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

.search-bar {
  background-color: $topbar-search-bg;
  border: 0;
  width: 100%;
  max-width: 270px;
  border-radius: 30px;
  height: 38px;
  padding: 8px 32px 8px 36px;

  &:focus {
    background-color: $topbar-search-bg;
    border: 0;
  }

  &:focus-visible {
    outline: 0;
  }
}

.xmark-icon {
  &:hover {
    opacity: 0.8;
  }
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
