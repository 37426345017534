//
// _profileDetails.scss
//

.profileDetails {
  display: flex;
  padding-top: 8px;
  position: relative;

  @include media-breakpoint-down('md') {
    flex-direction: column;
  }
}

.profileDetails-image {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;

  @include media-breakpoint-down('md') {
    max-width: initial;
  }
}

.profileDetails-info {
  position: relative;
  flex-grow: 2;
  margin-left: 32px;

  @include media-breakpoint-down('md') {
    margin-left: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.profileDetails-info-line {
  display: grid;
  grid-template-columns: 110px auto;
  column-gap: 20px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  & > * {
    font-size: 15px;
    margin-bottom: 0;
  }

  @include media-breakpoint-down('md') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.profileDetails-info-title {
  font-weight: 500;
}

.profileDetails-info-content {
  word-break: break-word;
  font-weight: 400;
}

.profileDetails-controls {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @include media-breakpoint-down('md') {
    position: initial;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }
}

.profilePosts-empty {
  height: 632px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePosts-thead {
  background-color: var(--#{$prefix}gray-100);
}

.profilePosts-td-max-width {
  word-break: break-all;
  -ms-word-break: break-all;
  -moz-word-break: break-all;
  -webkit-word-break: break-all;
  max-width: 45ch;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

.profilePosts-action-btns-box {
  display: flex;
}

.profilePosts-action-btns {
  display: flex;
  gap: 16px;
  max-width: 180px;
  min-width: 180px;
}

.profilePosts-action-view {
  width: 95px;

  @include media-breakpoint-down('md') {
    max-width: initial;
    width: 100%;
  }
}

.profilePosts-image {
  position: relative;
  width: 72px;
  border-radius: 8px;
  overflow: hidden;
}

.profilePosts-videoPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profilePosts-pagination {
  display: flex;
  justify-content: flex-end;
}

.postDetails-modalBody {
  img {
    max-width: 100%;
  }
}
