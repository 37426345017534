//
// _postReportsPage.scss
//

.postReportsPage_container {
  background-color: $white;
  border-radius: 4px;
  padding: 20px;
}

.postReportsPage_info_box {
  display: flex;
  gap: 32px;
  margin-bottom: 32px;

  @include media-breakpoint-down('md') {
    flex-direction: column;
    gap: 20px;
  }
}

.postReportsPage_image_box {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  border-radius: 8px;
  overflow: hidden;
  object-fit: cover;

  @include media-breakpoint-down('md') {
    max-width: initial;
    max-height: initial;
  }
}

.postReportsPage_image_video_play {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 36px;
  height: 36px;
}

.postReportsPage_info {
  height: fit-content;
  flex-grow: 2;
  display: grid;
  gap: 16px;
  grid-template-columns: 190px auto;

  @include media-breakpoint-down('md') {
    margin-left: 0;
    gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    :nth-child(2n) {
      margin-bottom: 8px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}

.postReportsPage_info_title {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  height: 44px;
  display: flex;
  align-items: center;
}

.postReportsPage_info_text {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: $gray-800;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.postReportsPage_edit_text {
  color: $gray-600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
}

.postReportsPage_creator {
  display: flex;
  align-items: center;
  gap: 12px;
}

.postReportsPage_avatar_box {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.postReportsPage_avatar {
  display: block;
  height: 100%;
  width: 100%;
}

.postReportsPage_info_actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  max-width: 180px;
  width: fit-content;
  & > * {
    height: fit-content;
  }

  @include media-breakpoint-down('md') {
    position: initial;
    max-width: initial;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }
}

.postReportsPage_td_message {
  word-break: break-all;
  -ms-word-break: break-all;
  -moz-word-break: break-all;
  -webkit-word-break: break-all;
  max-width: 45ch;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}

.centered-td {
  vertical-align: middle;
}
