//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

.disabled-page-button {
  pointer-events: none;

  & a {
    color: $gray-400;
  }
}

.custom-shevron {
  &-prev {
    & a {
      padding-left: 17px;
      padding-right: 17px;
      border-radius: 4px 0 0 4px;
      border-right: none;
    }
  }
  &-next {
    & a {
      padding-left: 17px;
      padding-right: 17px;
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
  }
}
