.auth-header {
  color: $white;
  background-color: $sidebar-dark-bg;
}

.auth-wrapper {
  display: flex;
  height: 90vh;

  > div {
    margin: auto;
  }
}

.password-input {
  position: relative;

  &-field {
    padding-right: 2.5rem;
  }

  &-button {
    position: absolute;
    top: 10px;
    right: 12px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.restore-password-message {
  padding: 32px;

  &-subtitle {
    max-width: 257px;
  }
}

.fg-pw-error-button {
  min-width: 89px;
}

.logout-font {
  color: $gray-800;
}

.border-radius-8 {
  border-radius: 8px;
}
