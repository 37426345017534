//
// _change-password.scss
//

.change-password-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-password-ok-btn {
  width: 80px;
}
