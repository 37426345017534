//
// _nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: var(--#{$prefix}gray-700);
      font-weight: $fw-medium;
    }
  }
}

.nav-pills {
  > a {
    color: var(--#{$prefix}gray-700);
    color: var(--#{$prefix}gray-700);
    color: var(--#{$prefix}gray-700);
    font-weight: $fw-medium;
  }
}

.nav-tabs-custom {
  padding: 12px;
  border-radius: 4px;
  background-color: var(--#{$prefix}white);

  .nav-item {
    position: relative;
    min-width: 180px;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down('md') {
      display: inline-flex;
      min-width: inherit;
      flex-grow: 1;
    }

    .nav-link {
      color: var(--#{$prefix}gray-600);
      border: none;
      &:hover {
        color: $primary;
      }
      &:hover::after {
        transform: scale(1);
        background: $primary;
      }
      &::after {
        content: '';
        background: $primary;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &::before {
        content: '';
        background: $nav-tabs-border-color;
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
      }

      &.active {
        color: $primary;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
}

.tab-content {
  padding: 20px;
  border-radius: 4px;
  background-color: var(--#{$prefix}white);
}

// vertical nav

.vertical-nav {
  .nav {
    .nav-link {
      padding: 24px 16px;
      text-align: center;
      margin-bottom: 8px;
      .nav-icon {
        font-size: 24px;
      }
    }
  }
}
